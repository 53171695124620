import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Hero from '../promo/Hero';
import PageHeader, { Header2 } from '../generic/PageHeader';
import Paragraphs from '../generic/Paragraphs';
import ContentSeparator from '../generic/ContentSeparator';
import StandardCollection12Promos from '../promo/collections/StandardCollection12Promos';
import { heroShape, promoShape } from '../promo/shapes';
import AzPage from '../generic/aToZ/Main';
import MetaProperties from '../generic/MetaProperties';

if (process.env.CLIENT) {
  require('./Page.scss'); // eslint-disable-line global-require
}

export const Page = ({
  description,
  hero,
  id,
  name,
  relatedRecipes,
  seasonalIngredients,
  ogImage,
  activeTab,
  azCounts,
  letter,
  page,
  totalCount,
}) => {
  const baseUrl = `/food/seasons/${id}`;
  return (
    <div className="season-page">
      <MetaProperties
        title={`${name} ingredients`}
        description={`Seasonal foods recipe ideas from BBC Food. ${description}`}
        imageUrl={ogImage}
        canonical={baseUrl}
      />
      <PageHeader
        title={`Seasonal ingredients in ${name}`}
        description={<Paragraphs text={description} />}
      />
      {hero && <Hero {...hero} />}
      {seasonalIngredients && seasonalIngredients.length > 0 && (
        <>
          <StandardCollection12Promos
            id={`season-${id}-ingredients`}
            promos={seasonalIngredients}
            maxCollectionSize={seasonalIngredients.length}
          />
          <ContentSeparator />
        </>
      )}
      <Header2 title="Also good to eat now" anchor="recipes" />
      <AzPage
        azCounts={azCounts}
        baseUrl={baseUrl}
        activeTab={activeTab}
        entity="recipes"
        letter={letter}
        page={page}
        promos={relatedRecipes}
        promoContainerClassName="season-page"
        anchor="recipes"
        totalCount={totalCount}
      />
    </div>
  );
};

Page.defaultProps = {
  hero: undefined,
  seasonalIngredients: undefined,
  activeTab: 'featured',
  azCounts: {},
  letter: '',
  page: 1,
};

Page.propTypes = {
  hero: PropTypes.shape(heroShape),
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  relatedRecipes: PropTypes.arrayOf(PropTypes.shape(promoShape)).isRequired,
  ogImage: PropTypes.string.isRequired,
  seasonalIngredients: PropTypes.arrayOf(PropTypes.shape(promoShape)),
  activeTab: PropTypes.oneOf(['all', 'featured']),
  azCounts: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  page: PropTypes.number,
  letter: PropTypes.string,
  totalCount: PropTypes.number.isRequired,
};

export default connect(({ seasonReducer }) => ({
  hero: seasonReducer.hero,
  id: seasonReducer.id,
  name: seasonReducer.name,
  description: seasonReducer.description,
  relatedRecipes: seasonReducer.relatedRecipes,
  seasonalIngredients: seasonReducer.seasonalIngredients,
  ogImage: seasonReducer.ogImage,
  activeTab: seasonReducer.activeTab,
  azCounts: seasonReducer.azCounts,
  letter: seasonReducer.letter,
  page: seasonReducer.page,
  totalCount: seasonReducer.totalCount,
}))(Page);
